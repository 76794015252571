import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import slider1 from "../../images/slider1.jpg"
import slider2 from "../../images/slider2.jpg"
import slider3 from "../../images/slider3.jpg"
import slider4 from "../../images/slider4.jpg"

function Herosection() {
    return (
        <div className="slidebar">
            <div className="row">
                <div className="col-md-12">
                    <div className='banner-slider'>
                        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                            <Carousel showArrows={false} showStatus={false} autoFocus autoPlay interval={4000} infiniteLoop showThumbs={false}>
                                <div className="banner-slick-slider-item">
                                    <img src={slider2} />
                                    <div className='overlay'></div>
                                    <p className="legend">
                                        <span style={{ fontSize: 30, fontWeight: 'bold'}}>Water Technology BD Ltd (WTB) &HKF; Engineering & Trading</span><br/>
                                        <span style={{ fontSize: 30, fontWeight: 'bold'}}>have grown and expanded its capabilities…….</span>
                                        
                                    </p>
                                </div>
                                <div className="banner-slick-slider-item">
                                    <img src={slider1} />
                                    <div className='overlay'></div>
                                    <p className="legend">
                                        <span style={{ fontSize: 30, fontWeight: 'bold'}}>Water technology BD Ltd.</span><br/>
                                        <span style={{ fontSize: 30, fontWeight: 'bold'}}> successfully working with
                                        Effluent Treatment Plant, <br/> Zero Discharge Plant, 3R Policy …
                                        </span>
                                       
                                    </p>
                                </div>
                                <div className="banner-slick-slider-item">
                                    <img src={slider3} />
                                    <div className='overlay'></div>
                                    <p className="legend">
                                        <span style={{ fontSize: 30, fontWeight: 'bold'}}>Water Technology BD Ltd (WTB)</span><br/>
                                        <span style={{ fontSize: 30, fontWeight: 'bold'}}>supplies industrial equipments…</span>
                                        
                                    </p>
                                </div>
                                <div className="banner-slick-slider-item">
                                    <img src={slider4} />
                                    <div className='overlay'></div>
                                    <p className="legend">
                                        <span style={{ fontSize: 30 }}> The Mutag BioChip 30TM was developed in 2008
                                            <br /> and is being manufactured  supplies all types of.. </span>
                                    </p>
                                </div>

                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Herosection