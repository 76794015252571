import React from 'react'

function Training() {
  return (
    <div className='training'>
      <div className='banner'>
        <h3>
          Environmental Training , Awareness & Education
        </h3>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='box'>
              <p>
                <strong>Training Program/Workshop/Seminar: </strong> WTBL offers different short Training Courses round the year. The training courses are conducted by our regular experts/engineers as well as expatriates from different Organizations and Universities in the globe. WTBL has started this training aiming to create awareness among the industry people, waste management practitioners, Government & non Government professionals, NGOs, students and general public working in the field of Environmental Management and Assessment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Training