import React from 'react'

function Discharge() {
    return (
        <div className='discharge'>
            <div className='banner'>
                <h3>
                    Zero Discharge Plant
                </h3>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <p>
                                Zero-liquid discharge (ZLD) is a water treatment process in which all wastewater is purified and recycled;
                                therefore, leaving zero discharge at the end of the treatment cycle. Zero liquid discharge is an advanced
                                wastewater treatment method that includes ultrafiltration, reverse osmosis, evaporation/crystallization,
                                and fractional electrodeionization.
                            </p>
                            <p>
                                <strong> Water Technology BD Ltd (WTB) </strong>
                                offers complete thermal and non-thermal ZLD solutions to manage tough-to-treat wastewaters. WTBL’s
                                proprietary evaporators, brine concentrators, and crystallizers can help recover more than 95% of your
                                plant’s wastewater while reducing the remaining brine as a product or solid.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row first-row'>
                    <div className='col-md-6'>
                        <div className='box'>
                            <h3>Zero Liquid Discharge Process:</h3>
                            <strong>Pretreatment and conditioning:</strong>
                            <p>
                                <strong>Pretreatment is used to remove simple things from the wastewater stream that can be filtered or precipitated
                                    out,</strong> conditioning the water and reducing the suspended solids and materials that would otherwise scale
                                and/or foul following treatment steps.
                            </p>
                            <p>
                                <strong>Ultrafiltration (UF)</strong> can also be used after the clarifiers instead of the gravity sand filter, or it can replace entire
                                clarification process altogether. Membranes have become the newest technology for treatment, pumping water directly
                                from the wastewater source through the UF (post-chlorination) and eliminating the entire clarifier/filtration train.
                            </p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='box'>
                            <h3>Phase-one concentration</h3>
                            <p>Concentrating in the earlier stages of ZLD is usually done with membranes like reverse osmosis (RO),
                                brine concentrators, or electro dialysis.
                            </p>
                            <p>Evaporation/crystallization:</p>
                            <p>After the concentration step is complete, the next step is generating a solid, which is done through
                                <strong>thermal processes or evaporation,</strong>  where you evaporate all the water off, collect it, and reuse it.</p>
                            <p>Recycled water distribution/solid waste treatment</p>
                            <p>If the <strong>treated water</strong> is being reused in an industrial process, it’s typically pumped into a holding
                                tank where it can be used based on the demands of the facility. The ZLD treatment system should have
                                purified the water enough to be reused safely in your process.</p>
                        </div>
                    </div>
                </div>
                <div className='row second-row'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <h3>Ultrafiltration (UF)</h3>
                            <p>Water Technology BD Ltd (WTB) successfully working withUltrafiltration (UF) that is a separation process using membranes with pore sizes in the range of 0.1 to 0.001 micron. Typically, UF membranes will remove high molecular-weight substances, colloidal materials, and organic and inorganic polymeric molecules. Low molecular-weight organics and ions such as sodium, calcium, magnesium chloride, and sulfate are not removed by UF Membranes. Because only high-molecular weight species are removed, the osmotic pressure differential across the UF Membrane surface is negligible. Low applied pressures are therefore sufficient to achieve high flux rates from an Ultrafiltration membrane. Flux of a membrane is defined as the amount of permeate produced per unit area of membrane surface per unit time. Generally flux is expressed as gallons per square foot per day (GFD) or as cubic meters per square meters per day.</p>
                        </div>
                    </div>
                </div>
                <div className='row third-row'>
                    <div className='col-md-6'>
                        <div className='box'>
                            <h3>Carbon Sand Filtration</h3>
                            <p>
                                The most common filters are filled with granular media such as sand/anthracite.
                                Filters are completely enclosed to use the line water pressure to push the influent through the media.

                                WTB offers a wide range of filters of different types and dimensions. Some of them are made entirely of Stainless Steel and can be operated either manually or automatically.
                            </p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='box'>
                            <h3>Membrane Bio-Reactor (MBR)</h3>
                            <p>
                                The term ‘membrane bioreactor’ (MBR) is generally used to define wastewater treatment processes where a perm-selective membrane eg; microfiltration or ultrafiltration is integrated with a biological process – a suspended growth bioreactor.<strong>Water Technology BD Ltd (WTB)</strong>  is a renewed Company that working with Membrane Bio-Reactor (MBR).
                            </p>
                            <p>MBRs differ from ‘polishing’ processes where the membrane is employed as a discrete tertiary treatment step with no return of the active biomass to the biological process.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Discharge