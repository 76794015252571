import React from 'react'
import work1 from "../../images/Work/work-1.jpg"
import work2 from "../../images/Work/work-2.jpg"
import work3 from "../../images/Work/work-3.jpg"
import work4 from "../../images/Work/work-4.jpg"
import work5 from "../../images/Work/work-5.jpg"
import work6 from "../../images/Work/work-6.jpg"

function Workgallery() {
    return (
        <div>
            <div className="dupont-list work-gallery list-CTA aem-GridColumn aem-GridColumn--default--12">
                <div className="list-basic-hover container-fluid container dynamic-comp-list">
                   
                    <div className="row">
                        <div className="col-6 col-sm-6 col-md-3 hover-list-item">
                            <div className="list-item">
                                <div className="card-image">
                                    <img src={work1}></img>
                                </div>
                                <a data-analytics="link_track" href="#" className="card-title">
                                    <h5>Moving Bed Bio-Reactor (MBBR)</h5>
                                    <span className="fa fa-long-arrow-alt-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 hover-list-item">
                            <div className="list-item">
                                <div className="card-image">
                                    <img src={work2}></img>
                                </div>
                                <a data-analytics="link_track" href="#" className="card-title">
                                    <h5>MEMCOR® CPII ULTRAFILTRATION SYSTEM</h5>
                                    <span className="fa fa-long-arrow-alt-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 hover-list-item">
                            <div className="list-item">
                                <div className="card-image">
                                    <img src={work3}></img>
                                </div>
                                <a data-analytics="link_track" href="#" className="card-title">
                                    <h5>MemPulse® MBR</h5>
                                    <span className="fa fa-long-arrow-alt-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 hover-list-item">
                            <div className="list-item">
                                <div className="card-image">
                                    <img src={work4}></img>
                                </div>
                                <a data-analytics="link_track" href="#" className="card-title">
                                    <h5>Multibore® Ultrafiltration Membrane</h5>
                                    <span className="fa fa-long-arrow-alt-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 hover-list-item">
                            <div className="list-item">
                                <div className="card-image">
                                    <img src={work5}></img>
                                </div>
                                <a data-analytics="link_track" href="#" className="card-title">
                                    <h5>DuPont UF Technology</h5>
                                    <span className="fa fa-long-arrow-alt-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 hover-list-item">
                            <div className="list-item">
                                <div className="card-image">
                                    <img src={work6}></img>
                                </div>
                                <a data-analytics="link_track" href="#" className="card-title">
                                    <h5>T-Rack® Integrated rack solutions</h5>
                                    <span className="fa fa-long-arrow-alt-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Workgallery