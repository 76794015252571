import React from 'react'
import filteration from '../../images/dupont/filteration.jpg'
import preteatment from '../../images/dupont/pretreatment.jpg'
import filmtech from "../../images/dupont/filmtech.jpg"
import desalitech from "../../images/dupont/desalitech.jpg"
import amberlight from "../../images/dupont/amberlight.jpg"
import electro from "../../images/dupont/electro.jpg"
import ligasep from "../../images/dupont/ligasep.jpg"

import innovation1 from "../../images/dupont/innovation-1.jpg"
import innovation2 from "../../images/dupont/innovation-2.jpg"
import innovation3 from "../../images/dupont/innovation-3.jpg"
import innovation4 from "../../images/dupont/innovation-4.jpg"

import ca1 from "../../images/dupont/ca-1.jpg"
import ca2 from "../../images/dupont/ca-2.jpg"
import ca3 from "../../images/dupont/ca-3.jpg"
import ca4 from "../../images/dupont/ca-4.jpg"
import ca5 from "../../images/dupont/ca-5.jpg"
import ca6 from "../../images/dupont/ca-6.jpg"

function Dupont() {
    return (
        <div className='dupont'>
            <div className='banner'>
                <div className='opacity'></div>
                <h3>
                    Dupont
                </h3>
            </div>
            <div className='container'>
                <h3>Industry-leading multi-tech portfolio
                    of filtration and purification technologies</h3>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='filteration'>
                            <div className='image'>
                                <img src={filteration}></img>
                            </div>
                            <div className='content'>
                                <h4>DuPont Ultrafiltration </h4>
                                <p>a comprehensive portfolio of
                                    membrane chemistries, modules
                                    and rack configurations
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration'>
                            <div className='image'>
                                <img src={preteatment}></img>
                            </div>

                            <div className='content'>
                                <h4>DuPont™ B-Free™ pretreatment </h4>
                                <p>a novel, vessel-based media
                                    technology to mitigate the effects
                                    of biofouling in RO systems
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration'>
                            <div className='image'>
                                <img src={filmtech}></img>
                            </div>
                            <div className='content'>
                                <h4>FilmTec™ reverse osmosis</h4>
                                <p>a broad portfolio of top-performing
                                    RO elements to achieve high,
                                    yet cost-effective, performance
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration'>
                            <div className='image'>
                                <img src={desalitech}></img>
                            </div>
                            <div className='content'>
                                <h4>DesaliTec™ SOAR closed circuit
                                    reverse osmosis</h4>
                                <p>a patented
                                    RO system for high recovery
                                    (up to 98%) demineralization
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration'>
                            <div className='image'>
                                <img src={amberlight}></img>
                            </div>
                            <div className='content'>
                                <h4>DuPont™ AmberLite™ ion exchange resins</h4>
                                <p>a diverse portfolio available in a wide
                                    range of chemical compositions and
                                    polymer structures
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration'>
                            <div className='image'>
                                <img src={electro}></img>
                            </div>
                            <div className='content'>
                                <h4>DuPont™ Electrodeionization</h4>
                                <p>a continuous, chemical-free and
                                    low maintenance technology to
                                    produce high purity water
                                </p></div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration'>
                            <div className='image'>
                                <img src={ligasep}></img>
                            </div>
                            <div className='content'>
                                <h4>DuPont™ Ligasep™ degasification modules</h4>
                                <p>a hollow-fiber chemical-free technology
                                    for dissolved gases removal in a small
                                    footprint
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <h3>Dry Seawater
                    Reverse Osmosis Elements</h3>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='content'>
                            <h4>Step-change innovation</h4>
                            <p>The new dry SWRO product portfolio is a breakthrough for the water treatment
                                industry. Continuous advances in membrane chemistry, testing methods and
                                procedures, together with automated precision single source manufacturing, enables
                                DuPont to be the only supplier to offer dry SWRO elements.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row'>

                    <div className='col-md-3'>
                        <div className='seawater'>
                            <div className='image'>
                                <img src={innovation1}></img>
                            </div>
                            <p>Greenhouse gas
                                emissions from
                                ~7 million
                                Miles driven by an
                                average passenger
                                vehicle</p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='seawater'>
                            <div className='image'>
                                <img src={innovation2}></img>
                            </div>
                            <p>CO2
                                emissions from
                                ~3 million
                                Pounds of coal
                                burned</p>
                        </div>
                    </div><div className='col-md-3'>
                        <div className='seawater'>
                            <div className='image'>
                                <img src={innovation3}></img>
                            </div>
                            <p>Greenhouse gas
                                emissions avoided by
                                ~1,000
                                Tons of waste
                                recycled instead of
                                landfilled
                            </p>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='seawater'>
                            <div className='image'>
                                <img src={innovation4}></img>
                            </div>
                            <p>Carbon sequestered by
                                ~50,000
                                Tree seedlings grown
                                for 10 years</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='container'>
                <h3>DuPont can help industrial customers address their key challenges</h3>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='filteration c-address'>
                            <div className='image'>
                                <img src={ca1}></img>
                            </div>
                            <div className='content'>
                                <p>Ensure consistent water quality
                                    under feed water quality
                                    fluctuations and complexity
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration c-address'>
                            <div className='image'>
                                <img src={ca2}></img>
                            </div>
                            <div className='content'>
                                <p>Provide solutions
                                    for limited footprint and
                                    mobile units
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration c-address'>
                            <div className='image'>
                                <img src={ca3}></img>
                            </div>
                            <div className='content'>
                                <p>
                                    Optimize water footprint
                                    to battle constrained water
                                    access
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration c-address'>
                            <div className='image'>
                                <img src={ca4}></img>
                            </div>
                            <div className='content'>
                                <p>
                                    Secure trouble-free operation to
                                    minimize unplanned downtime
                                    and maximize production
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration c-address'>
                            <div className='image'>
                                <img src={ca5}></img>
                            </div>
                            <div className='content'>
                                <p>
                                    Reduce or even eliminate chemical
                                    consumption to achieve more
                                    environmentally-friendly operations
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='filteration c-address'>
                            <div className='image'>
                                <img src={ca6}></img>
                            </div>
                            <div className='content'>
                                <p>
                                    Optimize energy consumption to
                                    reduce manufacturing cost and
                                    carbon footprint
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dupont