import React from 'react'

function AboutUs() {
  return (
    <div className='about'>
      <div className='banner'>
        <h3>
          About Us
        </h3>
      </div>
      <div className='container'>
        <h3><strong> Company Overview</strong></h3>
        <div className='row first'>
          <div className='col-md-12'>
            <div className='box'>
              <p>
                <strong> Water Technology BD Ltd (WTB) </strong> has grown and expanded its capabilities; one constant has always been the depth of expertise in all realms of wastewater services. Clients who turn to us for contract operations of wastewater facilities benefit from the added value we bring when compared with other firms because of our solid engineering skills – from facilities planning and troubleshooting through design, construction, start-up and operator training.
              </p>

              <p>
                We evaluate operations from a multitude of perspectives – from performance and infrastructure conditions, to staff skill levels and energy management. Then we identify how to maintain compliance and get the most out of a client’s facility by optimizing existing assets before considering costly capital investments. Safer, economical and significantly more effective, Water Technology BD Ltd (WTB) is revolutionizing the process of making water safe.
              </p>

            </div>
          </div>
        </div>

        <div className='row second'>
          <div className='col-md-6'>
            <div className='box'>
              <p>
                <strong> Water Technology BD Ltd (WTB) </strong> proudly conducted hundreds of Initial Environment Examination(IEE), Environmental Impact Assessment (EIA), Environmental Management Plan (EMP), Resettlement Plan, etc., & about forty Effluent Treatment Plant (ETP), Sewage Treatment Plant (STP) in various fields for the Textile (Dyeing, Printing and Weaving), Oil, Pulp & Paper, Gas & Energy, Tannery & Leather, Fertilizer, Pharmaceutical, Cement, Iron & Steel, Ceramic, Infrastructure, Food Industry, Chemical Industries, Power Sector etc. Water Technology BD Ltd (WTBL) specially working with Bangladeshi leading groups such as Meghna Group, City Group, Standard Group, TK Group etc. A group of capable Engineers & Technical Personal involved actively in this organization
              </p>

            </div>
          </div>

          <div className='col-md-6'>
            <div className='box'>
              <p>
                <strong> HKF Engineering & Trading (HKF) </strong>	 has stood up with the commitment in the field of water and wastewater treatment & management producing by establishing as a Proprietorship Firms in the year 2009. It’s been more than 9 years of glorious success by HKF Engineering & Trading bring the Company into new height. HKF Engineering & Trading specializes in all aspects of Structural, Civil/Site, Mechanical, Electrical, Chemical and Environmental Engineering. HKF provides primary engineering analysis, design, and construction observation for assignments such as wastewater treatment systems. HKF approaches all assignments with careful consideration.
              </p>

            </div>
          </div>
        </div>

        <div className='row third'>
          <div className='col-md-6'>
            <div className='box'>
              <h3>Our Business Policy: </h3>
              <ul>
                <li>Managing waste to protect the environment.</li>
                <li>Assure quality services.</li>
                <li>Abide by laws and regulations applied for production.</li>
                <li>Improve the effectiveness of Quality Management & review the achievements.</li>
                <li>Improve the performance by making and achieving quality objects.</li>
              </ul>

            </div>
          </div>

          <div className='col-md-6'>
            <div className='box'>
              <h3>Our Ethics</h3>
              <p><strong>Management:</strong> We want to attend highest level of competence through continuous development of professional management system in all aspects.</p>
              <p><strong>Goal:</strong> We will continue to build this great company by creating a strong sense of belonging almost all our staff through passion, commitment, drive and determination.</p>
              <p><strong>Activities:</strong> We aim to deliver our performance in excellence through our activities to
                satisfy our customers and gain their confidence.</p>

              <p> <strong>Excellence:</strong> We will never compromise our quality and always keep trying to offer the best for our customers.
              </p>

            </div>
          </div>
        </div>

        <div className='row fourth'>
          <div className='col-md-6'>
            <div className='box'>
              <h3>Mission: </h3>
              <p>
                <strong> Water Technology BD Ltd & HKF‘s</strong>  mission is to provide our customers with a competitive advantage. We are able to accomplish this by using proven technologies with internationally known components such as Siemens, Trundean, Johnson.</p>
              <p>The end result is that wastewater facilities we operate run efficiently and smoothly, as demonstrated by our 100% renewal rate at facilities past their first term.</p>

            </div>
          </div>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='box'>
                  <h3>Vission: </h3>
                  <p>vision is to provide the highest quality of service and maintain the highest levels of professional values in the fields of Water and Waste water Treatments plants.</p>

                </div>
              </div>

              <div className='col-md-12'>
                <div className='box'>
                  <h3>Our Strength: </h3>
                  <ul>
                    <li>Cost effective Project delivery.</li>
                    <li>Highly technical panel.</li>
                    <li>Ensure commitment to achieve the goal.</li>
                  </ul>

                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}

export default AboutUs