import React from 'react'
import { Link } from 'react-router-dom'
import anammox from "../../images/slider4.jpg"
import fb from "../../images/footer/icon-facebook.svg"
import twitter from "../../images/footer/icon-twitter.svg"
import linkedin from "../../images/footer/icon-linkedin.svg"
import youtube from "../../images/footer/icon-youtube.svg"
function Footer() {
    return (
        <div>
            <div className="footerref aem-GridColumn aem-GridColumn--default--12">
                <footer>
                    <div className="row">
                        <div className="col-sm-12 col-lg-3">
                            <div className='footer-image'>
                                <img src={anammox}></img>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <h4 className="footer-title footer-accordion">Contact Info<span className="fas fa-plus" /></h4>
                            <h4>Head Office</h4>
                            <p>House No: 1248, Road No: 09, Level: 4th, <br/> Mirpur DOHS, Dhaka, Bangladesh</p>

                            <h4>Factory</h4>
                            <p>329/330, DIT Industrial Area, Tongi, Gazipur.</p>

                            <h4>Phone number:</h4>
                            <span className='phone'>
                                <p>Mobile: +88 01713565696</p>
                                <p>Mobile: +88 01713273156</p>
                                <p>Phone: +88-02-58070365</p>
                            </span>
                        </div>

                        <div className="col-sm-12 col-lg-3">
                            <h4 className="footer-title footer-accordion">Services<span className="fas fa-plus" /></h4>
                            <ul className="footer-list1 accordion-closed">
                                <li><Link data-analytics="footer-link" to="/eia" >Environment Impact Assessment (EIA)</Link></li>
                                <li><Link data-analytics="footer-link" to="/effluent" data-title="Our Company">Effluent Treatment Plant (ETP)</Link></li>
                                <li><Link data-analytics="footer-link" to="/chemicals" data-title="Our Company">Chemicals</Link></li>
                                <li><Link data-analytics="footer-link" to="/machineries" data-title="Our Company">Machineries</Link></li>
                                <li><Link data-analytics="footer-link" to="/tripler" data-title="Our Company">3R Policy</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-12 col-lg-3 footer-social-icons">
                            <h4 className="footer-title footer-accordion">Follow Us<span className="fas fa-plus" /></h4>
                            <div className="col float-right no-gutters footer-social-icons" />
                            <ul>
                                <li><Link to="#"><img src={fb} /></Link></li>
                                <li><Link to="#"><img src={twitter} /></Link></li>
                                <li><Link to="#"><img src={linkedin} /></Link></li>
                                <li><Link to="#"><img src={youtube} /></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copyright">
                                <p>Copyright © WTB - 2023 | All rights reserved | Developed by- <Link target="_blank" to='https://ideahubbd.com/'>ideahub</Link></p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

        </div>
    )
}

export default Footer