import React from 'react'

function Homecontact() {
    return (
        <div className='container'>
            <div className="home-contact cmp-featuredBlock cta-type aem-GridColumn aem-GridColumn--default--12">
                <div className="container-fluid featuredBlock bg-image-view noPaddings">
                    <div className="container">
                        <div className="row float">
                            <div className="col-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 featuredBlock-col no-se">
                                <h2><p>Not sure where to start? What to Do?</p>
                                </h2>
                                <div className="body2">
                                    <p>Contact With Us Without Any hesitation</p>
                                </div>
                                <a className="cta" data-analytics="link_track" href="#">Contact Us <i className="fas fa-long-arrow-alt-right" /> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Homecontact