import React from 'react'

function Biochip() {
    return (
        <div className='biochip'>
            <div className='banner'>
                <h3>
                    Mutag Biochip 30 TM
                </h3>
            </div>
            <div className='container'>
                <h3>Biochip 30 TM</h3>
                <div className='row first'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <p>
                                BioChip 30TM was developed and manufactured in Germany and is guaranteed as a product made from experience. The BioChip 30TM is manufactured of mainly PE or recycled materials. BioChip 30TM is specially designed with high-quality pore system, thickness of only 1.1mm and light-weight to optimize its efficiency by providing higher removal rates and more reliable process stability. The active surface area of 5,500 m²/m³ of BioChip 30TM has been scientifically certified and this provides an upper hand as compared to a range of 500 m²/m³ – 800 m²/m³ offered by competitive solutions. It is assured to provide high removal rates and reliable process stability. Other than that the active surface area, it has advantages of self-cleaning, long-life abrasion resistant, and also cost saving as compared to conventional media carrier. Due to these properties, the BioChip 30TM are currently widely applied in treatment systems such as COD/BOD Degradation, Nitrification, Denitrification, ANAMMOX processes.
                            </p>
                            <strong>Technical Specifications</strong>

                            <ul>
                                <li>Material ~ High quality PE material free from carcinogenic plasticizers</li>
                                <li>Circular ~ Ø 30 mm (1“)</li>
                                <li>Thickness ~ 1.1 mm</li>
                                <li>PE material</li>
                                <li>Active surface area &gt;5,500 m²/m³</li>
                            </ul>

                            <p>
                                The BioChip 30TM biofilm carrier has the surface and pore structure that is evenly distributed on a diameter of 30mm (1”) and due to its openings, it provides an optimal habitat for the microorganisms. The BioChip 30TM has a homogeneous outer ring as buffer zone, protecting the pore structure from potential damage/abrasion/cuts. Besides, due to its low net weight, the kinetic energy is also that low so any mechanical wear is prevented.
                            </p>

                        </div>
                    </div>
                </div>

                <div className='row second'>
                    <div className='col-md-6'>
                        <div className='box'>
                            <strong> Performance</strong>

                            <p>
                                The BioChip 30TM high-performance carrier provides an active surface area of more than 5,500 m²/m³ which is proven in numerous large-scale applications and also scientifically certified by means of nanostructure measurements of the carrier surface area. The carrier media has been specifically developed for the biodegradation of carbon and the elimination of nitrogen in industrial and municipal wastewater treatment plants. Due its specific characteristics, it can be used equally for aerobic, anaerobic and anoxic processes. The results are extremely high removal rates in denitrification, nitrification and COD elimination process.
                            </p>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='box'>
                            <strong> Quality</strong>

                            <p>
                                With regards to environmental and health-related aspects, the BioChip 30TM is exclusively made of virgin PE material (no re-granulates from recycled material) and does not contain any plasticizers which are under strong suspicion of being carcinogenic. The BioChip 30TM material is very flexible, abrasion-resistant, and it does not break under pressure stress. Any unfoamed biofilm carriers with larger hollow spaces (small tube type, fan washer type, molded media) do not provide any suchlike buffer and, once exposed to pressure, they can get damaged very easily or get fine hairline cracks which may lead to a complete breakage at a later point of time.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='row fourth'>
                    <div className='col-md-6'>
                        <div className='box'>
                            <strong> Cost saving</strong>

                            <p>
                                The high biodegradation performance of the BioChip 30TM carrier with large active surface area of more than 5,500 m²/m³ allows for using less carrier volume than with “conventional” carriers since there is less BioChip 30TM volume required for providing the similar protected active surface area. Customers can save money due to smaller tank volumes, and it is a crucial feature when there is only limited space available when building a new WWTP. Besides, when less carrier volume required means less transport volumes in shipment at similarly large surface area thus the transport cost will therefore become low.
                            </p>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='box'>
                            <strong> Alternative product “BioFlakes”</strong>

                            <p>
                                As a favorable alternative to the BioChip 30TM, BioFlakes is offered as an alternative product with lower cost.
                            </p>
                            <p>
                                The BioFlakes have a diameter of approximately 30mm with 1.1 thickness and is made with PE re-granulate.
                            </p>
                            <p>
                                On request, we shall be pleased to provide you with more information.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='row third'>
                    <div className='col-md-4'>
                        <div className='box'>
                            <strong> Diffusion Depth</strong>

                            <p>
                                Typically, the optimal supply of the biofilms with substrates and oxygen is reached at a layer thickness/depth of up to approx. 0.5 mm, this is made possible by the chip thickness of approx. 1.1 mm. The BioChip 30TM is carefully designed so that the pores on the carrier will be permanently filled with active biomass and at the same time allowing an appropriate diffusion
                            </p>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='box'>
                            <strong> High removal performance due to specific carrier characteristics:</strong>

                            <p>
                                BioChip 30TM with a protected active surface area of more than 5,500 m²/m³ has a removal performance of 10 times higher per m³ when compare to other carrier media. The biofilms which establish inside the pores are kept very thin due to self-cleaning by shear forces which work on the outer side of the carrier (resulting from the carrier’s movement in the wastewater) whereas, the diffusion depth of biofilms, thin biofilms are essential for an optimal substrate and oxygen transfer from the water to the microorganisms.
                            </p>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='box'>
                            <strong> Long life abrasion resistant material</strong>

                            <p>
                                One single element of the BioChip 30TM media has a very low mass compared to its size. Due to low kinetic energy, the impulse transferred in the case of contact with another carrier element or the tank walls are that insignificant that each kind of abrasion and wear is maximally reduced. The paraboloid design also allows optimal movement. Contrary to that, it is not a rarely occurring phenomenon that several tubular carrier types (often injection-molded and heavy related to their size) break after a certain time in operation.
                            </p>
                        </div>
                    </div>
                </div>

                

                <div className='row fifth'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <strong>Self-cleaning and non-clogging</strong>

                            <p>
                                Clogging shall mean the unintended and uncontrolled growth of microorganisms as it can be observed with many conventional tube-shaped carriers, particularly in wastewater with high loads of readily biodegradable COD. The movement of the carrier elements in the wastewater creates shear forces at the outside of the BioChip 30TM carriers, which permanently abrade the biomass growing out of the pores preventing it from clogging. Consequently, the biofilms are permanently thin and the optimal transfer of substrate, nutrients and oxygen from the wastewater to all biofilm layers is ensured. Clogged carrier elements (please refer to the picture below) are characterized by thick biofilms which do not allow for the deeper biofilm layers being supplied with substrate, nutrients and oxygen. Consequently, these deeper layers are not at all or only hardly biologically active, whereas the thick biofilms reduce the surface area purposed for the attachment of active microorganisms, resulting in a decrease in biodegradation capacity.
                            </p>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default Biochip