import React from 'react'
import news1 from "../../images/news/news1.jpg"
import news2 from "../../images/news/news2.jpg"
import news3 from "../../images/news/news3.jpg"
import news4 from "../../images/news/news4.jpg"

function Newsfeed() {
    return (
        <div className='newsfeed-section'>
            <div className='container'>
                <div className='row'>
                    <div className="col-md-12 story-title">
                        <h5>Featured News</h5>
                        <a data-analytics="link_track" data-title="Featured News" />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div>
                            <iframe width="100%" height="400px" src="https://www.youtube.com/embed/fjPA8eH10YI?feature=oembed" frameBorder={0} allow="autoplay; encrypted-media" allowFullScreen />
                            <h3>Moving Bed Biofilm Reactor (MBBR)</h3>
                            <p>
                                Moving Bed Biofilm Reactor (MBBR) Technology is the cutting edge of biological treatment
                                in Wastewater Treatment Plant (WWTP). The MBBR technologyis currently
                                the most advanced solution for COD/BOD Degradation, Nitrification and Denitrification Processes
                            </p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="list-item">
                                    <div className="card-image">
                                        <img src={news1}></img>
                                    </div>
                                    <a data-analytics="link_track" href="#" className="card-title">
                                        <h5>Industrial Chemicals</h5>
                                        <span className="fa fa-long-arrow-alt-right" />
                                    </a>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="list-item">
                                    <div className="card-image">
                                        <img src={news2}></img>
                                    </div>
                                    <a data-analytics="link_track" href="#" className="card-title">
                                        <h5>Water Technology  Summit & Exhibition</h5>
                                        <span className="fa fa-long-arrow-alt-right" />
                                    </a>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="list-item">
                                    <div className="card-image">
                                        <img src={news3}></img>
                                    </div>
                                    <a data-analytics="link_track" href="#" className="card-title">
                                        <h5>Water treatment Machineries</h5>
                                        <span className="fa fa-long-arrow-alt-right" />
                                    </a>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="list-item">
                                    <div className="card-image">
                                        <img src={news4}></img>
                                    </div>
                                    <a data-analytics="link_track" href="#" className="card-title">
                                        <h5>Water & Waste Water Treatment Plant</h5>
                                        <span className="fa fa-long-arrow-alt-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsfeed