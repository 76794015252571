import React from 'react'

function ThreeRPolicy() {
    return (
        <div className='threer'>
            <div className='banner'>
                <h3>
                    3R Policy
                </h3>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <p>
                                Throughout human history, water has been a source of life as well as of death. Water is so common a natural resource that its availability is taken for granted. An adequate quantity of water for meeting basic human needs is a prerequisite for existence, health, and development. As development proceeds, the demand for water will invariably increase. Despite our understanding about the vitality of water for development, and its crucial role in meeting basic human needs, we continued with our extravagant water-use patterns as if the availability of fresh water on the planet is unlimited.
                            </p>
                            <p>
                                Fortunately for the human race, the unsustainable nature of water-use patterns, despite modern technology and feats of engineering, has been dawning upon us for the last decade or so. Water planners in many corners of the world are projecting that within two decades, availability of freshwater sources will fall short of needs. Water is mostly taken from rivers or aquifers. Water that has been withdrawn, used for some purpose, and returned to the environment will be polluted, making it unfit for further beneficial uses. In many countries, a large proportion of such polluted water (i.e. wastewater), is discharged into the environment with little or no treatment. As water demands increase, not only must large quantities of fresh water be made available, but natural water sources are being relied upon to dilute the polluted water discharged, compounding further the limited availability of freshwater. In many parts of the world, there is a widespread scarcity, gradual destruction, and increased pollution of freshwater resources. In the developing world, one person in three lacks safe drinking water and sanitation: the basic requirement for health and dignity
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row first-row'>
                    <div className='col-md-12'>
                        <p><strong>Water Technology BD (WTB) Ltd </strong> satisfactorily working with Reduce, Reuse & Recycle are described below:</p>
                    </div>
                </div>

                <div className='row second-row'>

                    <div className='col-md-6'>
                        <div className='box'>
                            <p><strong>REDUCE –</strong> throw away less by buying only what you need and by buying fewer disposable and over-packaged products.</p>
                            <ol>
                                <li>Use washable dishes instead of paper plates and cups.</li>
                                <li>Use a reusable water bottle instead of single serve water bottles.</li>
                                <li>Bring your own bag to the grocery store.</li>
                                <li>Pack a litter less lunch with only containers that can be recycled or washed.</li>
                                <li>Have furniture, toys and appliances repaired instead of tossing them and getting new ones.</li>
                            </ol>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='box'>
                            <p><strong>REUSE –</strong> use items over and over or give gently used items to others.</p>
                            <ol>
                                <li>Reuse containers, bags boxes, tubs, cups, bottles and jars over and over before discarding or recycling.</li>
                                <li>Pass down outgrown clothing, books and toys to younger brothers and sisters, donate to charity or have a yard sale.</li>
                                <li> Collect rain water in a rain barrel to use to water the garden.</li>
                                <li>Take “garbage” and make it into something new to use or to play with.</li>
                                <li>Take your food scraps and yard waste and start a compost bin in your back yard.</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className='row'>


                    <div className='col-md-12'>
                        <div className='box'>
                            <p><strong>RECYCLE –</strong> as much as you can by collecting recyclable materials to be picked up at the curb or taken to a drop-off location and made into new products.</p>
                            <ol>
                                <li>Collect paper and beverage containers and find the best way to recycle them in your community so they can be made into recycled paper and new beverage containers.</li>
                                <li>Have your school work with a recycling partner to collect and recycle ink jet cartridges, cell phones, bottle caps or aluminum tabs.</li>
                                <li>Collect aluminum cans and take to your local metal recycler for redemption.</li>
                                <li>Look for the recycling symbol and buy recycled products like recycled content paper, cards, and paper towels.</li>
                                <li>Encourage your parents to look for recycling centers that accept things like tires, batteries, motor oil, paints, and electronics for recycling too.</li>
                            </ol>
                        </div>
                    </div>

                    <div className='col-md-12'>
                        <div className='box'>
                            <p><strong>Why should we bother to reduce, reuse and recycle?</strong></p>
                            <p><strong>It’s easy – </strong> Most localities have curbside pick-up or drop-off locations for your recyclables</p>
                            <p>It saves natural resources – Plastic is made from petroleum, aluminum from an ore called bauxite, tin cans from mostly steel, and paper from trees. Recycling these materials means fewer natural resources need to be mined or harvested. Glass, steel, aluminum and plastic can be recycled over and over again.</p>
                            <p><strong>It saves money – </strong> Recycling saves fuel and transportation costs and saves on garbage disposal fees.</p>
                            <p><strong>It saves energy – </strong> Recycling just one aluminum can instead of making it from scratch saves enough energy to run a computer for 3 hours.</p>
                            <p><strong>It saves landfill space –</strong> Our landfills are getting full. Putting only materials that can’t be reused or recycled in the trash will make landfills last longer. Plastic, aluminum, paper, and other recyclable materials do us no good trapped in a landfill.</p>
                            <p>It reduces pollution and protects the environment – Recycling reduces air and water pollution and mining waste because it uses less energy and causes less damage to land and water than mining and harvesting.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThreeRPolicy