import React from 'react'

function IEE() {
  return (
    <div className='iee'>
      <div className='banner'>
        <h3>
          Initial Environmental Examination (IEE)
        </h3>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='box'>
              <strong> Initial Environmental Examination (IEE):</strong>

              <p>
                IEE will be carried out in relation to the project works, and the operational characteristics of the project. During this stage, a review of existing environmental data sources will be carried out and site visits will be made to gain familiarization with the proposed project in its environmental and social setting. Consultations will be held with various interested parties as a means of obtaining the background information which is necessary to ensure that all significant issues and impacts, both perceived and real, are given due consideration, and that any proposed benefit enhancement and mitigation measures will not only be effective, but will also be acceptable to all concerned.
              </p>

              <p>
              IEE will also take the form of a scoping exercise to identify the key impacts and issues, which need to be addressed in detail. This examination will cover both biophysical and social/socio-economic impacts, direct and indirect, which may occur as a result of project implementation. Off-site impacts those arising from the development and operation plant at the proposed site will also be covered. A review of any pre-existing impacts (from secondary data sources) associated with past implementation of the similar project and its present operational characteristics will also be carried out, and consideration will be given to the extent to which corrective measures should be incorporated within the present project so that the plant will not create any adverse impact at its proposed location.
              </p>

              <p>
              To fulfill scope of work the studies will be conducted in a systematic way by following activities:
              </p>

              <p>i. Site visit(s) to obtain information on existing facilities</p>
              <p>ii. Air, Water and Sound Quality Monitoring in the project areas.</p>
              <p>iii. Collecting Secondary Information Necessary to Conduct IEE Study.</p>
              <p>iv. Formal Guidance and assistance for Getting Required NOCs.</p>
              <p>v. Ecological and Land-use survey at the project and surrounding area.</p>
              <p>vi. Assess environmental impacts of the project on surrounding environment</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IEE