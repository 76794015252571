import logo from './logo.svg';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import "../src/css/all.min.css";
import "../src/css/bootstrap.min.css";
import "../src/css/style.css";
import Header from './Component/Header/Header';
import Homesection from './Component/HomeSection/Homesection';
import AboutUs from './Component/About/AboutUs';
import Footer from './Component/Footer/Footer';
import Effluent from './Component/Services/Effluent';
import Discharge from './Component/Services/Discharge';
import ThreeRPolicy from './Component/Services/ThreeRPolicy';
import SewageTreatment from './Component/Services/SewageTreatment';
import WaterTreatment from './Component/Services/WaterTreatment';
import EIA from './Component/Services/EIA';
import EMP from './Component/Services/EMP';
import IEE from './Component/Services/IEE';
import Environment from './Component/Services/Environment';
import Training from './Component/Services/Training';
import Economic from './Component/Services/Economic';
import Feasibility from './Component/Services/Feasibility';
import Chemicals from './Component/Services/Chemicals';
import Machineries from './Component/Services/Machineries';
import Biochip from './Component/Products/Biochip';
import Clients from './Component/Clients/Clients';
import Contact from './Component/Contactt/Contact';
import Gallery from './Component/Gallery/Gallery';
import Tecnologies from './Component/Technologies/Tecnologies';
import Dupont from './Component/Dupont/Dupont';
import DupontSeminar from './Component/DupontSeminar/DupontSeminar';

function App() {
  return (
    <div className="App container-fluid">
      <Header />
      <Routes>
        <Route path="/" element={<Homesection />} />
        <Route path="/effluent" element={<Effluent />} />
        <Route path="/discharge" element={<Discharge />} />
        <Route path="/tripler" element={<ThreeRPolicy />} />
        <Route path="/sewagetreatment" element={<SewageTreatment />} />
        <Route path="/watertreatment" element={<WaterTreatment />} />
        <Route path="/eia" element={<EIA />} />
        <Route path="/environment" element={<Environment />} />
        <Route path="/emp" element={<EMP />} />
        <Route path="/iee" element={<IEE />} />
        <Route path="/training" element={<Training />} />
        <Route path="/economic" element={<Economic />} />
        <Route path="/feasibility" element={<Feasibility />} />
        <Route path="/chemicals" element={<Chemicals />} />
        <Route path="/machineries" element={<Machineries />} />
        <Route path="/biochip" element={<Biochip />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/technologies" element={<Tecnologies />} />
        <Route path="/dupont" element={<Dupont />} /> 
        <Route path="/dupontseminar" element={<DupontSeminar />} /> 
        
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      {/* <Homesection /> */}
      <Footer />


    </div>
  );
}

export default App;
