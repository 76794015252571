import React from 'react'

function Machineries() {
  return (
    <div className='chemical'>
      <div className='banner'>
        <h3>
          Machineries
        </h3>
      </div>
      <div className='container'>
        <div className='row first'>
          <div className='col-md-6'>
            <div className='box'>
              <p>
                <strong>Water Technology BD Ltd (WTB) & HKF Engineering & Trading</strong> supply Belt presses that work by removing sludge onto two different dewatering belts where freestanding water molecules are separated from the sludge by gravity and are collected by a trough.
              </p>
              <p>It is commonly used for the following applications:</p>
              <ul>
                <li>Paper mill sludge</li>
                <li>River silt</li>
              </ul>
              <p>Municipal wastewater treatment plants etc.</p>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='box'>
                  <strong>Screw press filter</strong>

                  <p>
                    A screw press is a type of machine press in which the ram is driven up and down by a screw. The screw shaft can be driven by a handle or a wheel. It works by using a coarse screw to convert the rotation of the handle or drive-wheel into a small downward movement of greater force.
                  </p>
                  <p>
                    The overhead handle usually incorporates balls as flyweights. The weights helps to maintain the momentum and thrust of the tool to make it easier to operate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row second'>

          <div className='col-md-6'>
            <div className='box'>
              <strong>Dissolved Air Flotation (DAF)</strong>

              <p>
                <strong> Water Technology BD Ltd (WTB) & HKF Engineering & Trading’s </strong> Dissolved Air Flotation (DAF) wastewater treatment plant is typically used in a wide range of industrial applications to mitigate the financial impact of trade effluent charges, and maintain environmental compliance.

                The WTB DAF wastewater treatment system is widely used for the reduction of Chemical Oxygen Demand (COD) present in fats, oils and greases, color, organic matter, biological sludges and colloidal material.
              </p>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='box'>
              <strong>Rotary Drums Screens</strong>

              <p>
                Water Technology BD Ltd supplies a range of internally fed Rotary Drum Screens. Our screens consist of a wedge wire drum inside which is an internal feed tank.</p>

              <p>The internal feed tank has the function of controlling velocities and reducing the force of the flow onto the wedge wire drum.
              </p>
              <ul>
                <li>Paper mills</li>
                <li>Abattoirs</li>
                <li>Food&nbsp;industry etc.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className='row third'>
          <div className='col-md-6'>
            <div className='box'>
              <strong>Programmable Logic Controller (PLC) Board</strong>

              <p>
                With a wastewater programmable logic controller (PLC) from Water Technology BD Ltd (WTB), you will have an automated program making chemical and engineering decisions for your treatment system, ensuring that processes are completed in an efficient manner with minimal operator oversight required.
              </p>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='box'>
              <strong> Diffuser or membrane diffuser</strong>

              <p>
                An air diffuser or membrane diffuser is an aeration device typically in the shape of a disc, tube or plate, which is used to transfer air and with that oxygen into sewage or industrial wastewater. Oxygen is required by microorganisms/bacteria residents in the water to break down the pollutants.</p>

              <p>Water Technology BD Ltd (WTB) & HKF Engineering & Trading successfully supply Diffusers that are use either rubber membranes or ceramic elements typically and produce either fine or coarse bubbles.
              </p>
            </div>
          </div>
        </div>

        <div className='row fourth'>
          <h3>Request for Quote</h3>
          <p>To request for the quote simply call us +88-02-58070365 or +88-01713565696</p>
        </div>
      </div>
    </div>
  )
}

export default Machineries