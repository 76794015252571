import React from 'react'
import slider1 from "../../images/slider1.jpg"
import opdi from "../../images/information-picture/op-di.jpg"
import buffer from "../../images/information-picture/buffer.png"
import anammox from "../../images/information-picture/anammox.jpg"

function information() {
    return (
        <div className="cmp-featuredBlock full-image aem-GridColumn aem-GridColumn--default--12">
            <div className="container-fluid featuredBlock    " id="featuredblock_copy_c_852890433" style={{ backgroundColor: '' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 featuredBlock-col text-col-single">
                            <div className="center-text-box">
                                <h3 id="bigParagraph"><p><b>
                                    MBBR</b></p>
                                    <p><b>
                                        Mutag BioChip 30™ carriers</b></p>
                                </h3>
                                <div className="body2">
                                    <p>Mutag BioChip30TM was developed in 2008 and is being solely manufactured in Germany.From the experience gained in the large-scale application of different biofilm carrier types available on the market, the Mutag BioChip30™ was finally developed internally at Mutag.</p>
                                    <p>&nbsp;</p>
                                </div>
                                <a className="cta" data-analytics="link_track" href="#" data-type="video" data-title="featuredBlock">Learn More</a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 featuredBlock-col test image" ></div>
                    </div>
                </div>
            </div>


            <div className="container-fluid featuredBlock">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 featuredBlock-col" >
                            <div className='row justify-content-center'>
                                <div className='col-md-6'>
                                    <img src={buffer}></img>
                                </div>
                                <div className='col-md-6'>
                                    <img src={anammox}></img>
                                </div>
                                <div className="w-100 p-3"></div>
                                <div className='col-md-6'>
                                    <img style={{ borderRadius: '50%' }} src={opdi}></img>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 featuredBlock-col text-col-single-2">
                            <div className="center-text-box">
                                <h3 id="bigParagraph"><p><b>
                                    Technical Specifications</b></p>
                                </h3>
                                <div className="body2">
                                    <ul>
                                        <li>Circular ~ Ø 30 mm (”)</li>
                                        <li>Thickness ~ 1.1 mm</li>
                                        <li>Virgin PE material</li>
                                        <li>Active surface area &gt;5,500 m²/m³</li>
                                        <li>Biomass concentration specified acc. to the application purpose.</li>
                                    </ul>
                                </div>
                                <a className="cta" data-analytics="link_track" style={{ color: '', borderColor: '' }} href="#" data-type="video" data-title="featuredBlock">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}

export default information