import React from 'react'

function Chemicals() {
  return (
    <div className='chemical'>
      <div className='banner'>
        <h3>
          Chemicals
        </h3>
      </div>
      <div className='container'>
        <div className='row first'>
          <div className='col-md-8'>
            <div className='box'>
              <strong>Poly Aluminum Chloride (PAC)</strong>

              <p>
                Polyaluminum Chloride (PAC) is one of the most efficient water treatment chemicals utilized today. It is widely used in both potable water and wastewater treatment because it provides high coagulation efficiency and it has the widest pH and temperature application ranges compared to other water treatment chemicals. Depending on water chemistry and process specifications, specific blend types can be formulated to meet the demands of high quality water production and reduce overall treatment cost. Please contact us (Water technology BD Ltd & HKF Engineering & Trading) for more information, pricing and /or samples of these innovative products
              </p>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='box'>
                  <strong>Polymer</strong>

                  <p>
                    A polymer is a very large organic molecule; it is a chain of monomer subunits. In wastewater treatment processes Water technology BD Ltd & HKF Engineering & Trading supply polymers that are used to coagulate suspended solids and produce large curds of solid materials (floc).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row second'>

          <div className='col-md-4'>
            <div className='box'>
              <strong>Generator Cooling Chemicals</strong>

              <p>
                The cooling water of the engine should be only demineralized (distilled) water with proper treatment, which is necessary for keeping effective cooling and preventing corrosion of the system.
                Though the distilled water matches best to the requirements for cooling water, it is necessary to add corrosion inhibitor.
              </p>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='box'>
              <strong>De coloring Agent</strong>

              <p>
                <strong> Water technology BD Ltd & HKF Engineering & Trading </strong> supply Water De coloring Agent that is quaternary cationic polymer which is specialized product for de coloring, flocculating, COD decreasing and other applications.
              </p>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='box'>
              <strong>Boiler Cooling Chemicals</strong>

              <p>
                Water treatment of boilers and cooling towers is an integral operation for many industries, with productivity and product quality hanging in the balance. Water technology BD Ltd & HKF Engineering & Trading supply efficient Boiler Cooling chemicals.
              </p>
            </div>
          </div>
        </div>

        <div className='row third'>
          <div className='col-md-6'>
            <div className='box'>
              <strong>Anti foaming agents</strong>

              <p>
                <strong> Water Technology BD Ltd (WTB) & HKF Engineering & Trading </strong> supply Anti-foaming agents that act so that their molecules are adsorbed on the gas-liquid interface and thus suppress the molecule surface active agent that causes foaming. So the whole film around the air bubbles, which leads to a destabilizing weakening its membrane and the connectivity of smaller bubbles into a bigger one.
              </p>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='box products'>
              <strong>Our Products</strong>
              <ul>
                <li>Chemicals for ETP &amp; WTP</li>
                <li>Chemicals for Boiler &amp; Cooling water Treatment program.</li>
                <li>HCL &amp; H2SO4</li>
                <li>Paper Chemicals</li>
                <li>Polymers &amp; Additives</li>
                <li>Sugar Chemicals</li>
                <li>Textile Chemicals</li>
                <li>Leather Chemicals</li>
              </ul>
            </div>
          </div>
        </div>

        <div className='row fourth'>
          <h3>Request for Quote</h3>
          <p>To request for the quote simply call us +88-02-58070365 or +88-01713565696</p>
        </div>
      </div>
    </div>
  )
}

export default Chemicals