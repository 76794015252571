import React from 'react'

function Environment() {
    return (
        <div className='environment'>
            <div className='banner'>
                <h3>
                    Initial Environmental Examination (IEE)
                </h3>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <strong> Environmental and Social Impact Assessment (ESIA)</strong>

                            <p>
                                A process for predicting and assessing the potential environmental and social impacts of a proposed project, evaluating alternatives and designing appropriate mitigation, management and monitoring measures.
                            </p>

                            <p>
                                Any major development project anywhere in the world usually requires the production of an environmental and social impact assessment (ESIA), which is often undertaken in accordance with the requirements of international finance institutions, to gain national permits to operate and secure financing.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Environment