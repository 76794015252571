import React from 'react'
import drumpic from '../../images/Drum-Screener.jpg'
import airpic from '../../images/Air-Flotation.jpg'

function Tecnologies() {
    return (
        <div className='technologies'>
            <div className='banner'>
                <h3>
                    TECHNOLOGIES
                </h3>
            </div>
            <div className='container'>
                <div className='row first' id='mutag'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <h3>Mutag BioChip30TM</h3>
                            <p>
                                The Mutag BioChip30TM was developed in 2008 and is being solely manufactured in Germany. From the experience gained in the large-scale application of different biofilm carrier types available on the market, the Mutag BioChip30™ was finally developed internally at Mutag.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='row second' id='rotary'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <img src={drumpic}></img>
                                </div>
                                <div className='col-md-9'>
                                    <h3>Rotary Drum Screen</h3>
                                    <p>
                                        Compared to Static Screens, Rotary Drum Screens can cope with a larger flowrate in a smaller footprint. The solids from the wastewater will be separated by a wedge wired rotating drum from the liquid and then washed out by a set of internal and external pipes with nozzles. Rotary Drum Screen uses high-pressure washing to eliminate blocking of the screen and ensures the screen surface is solid-free to be ready for another cycle. The separated liquid is discharged from the bottom of the drum to the next point of treatment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row third' id='daf'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <img src={airpic}></img>
                                </div>
                                <div className='col-md-9'>
                                    <h3>Dissolved Air Floatation (DAF)</h3>
                                    <p>
                                        Dissolved Air Floatation (DAF) is an established physical/chemical technology and proven for its diversity on treating different industrial and municipal wastewaters. DAF system is designed to remove suspended solids (TSS), oils and greases (O&G) and other pollutants from wastewater. The process of DAF is based on the concept of dissolved air-in-water solution which is produced by feeding air to the incoming wastewater forming tiny bubbles that attach to the contaminants causing them to float. The floating sludge is then scrapped of the surface of the water into the sludge compartment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row fourth' id='mbbr'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <iframe width="100%" height="400px" src="https://www.youtube.com/embed/fjPA8eH10YI?feature=oembed" frameBorder={0} allow="autoplay; encrypted-media" allowFullScreen />
                                </div>
                                <div className='col-md-8'>
                                    <h3>Moving Bed Biofilm Reactor (MBBR)</h3>
                                    <p>
                                        Moving Bed Biofilm Reactor (MBBR) Technology is the cutting edge of biological treatment in Wastewater Treatment Plant (WWTP) The MBBR Technology is currently the most advanced solution for COD/BOD Degradation, Nutrification, Denitrification and Anmmox Processes. The technology in MBBR system which employs BioChip as the biological carrier has proven to deliver consistent and outstanding performances in many application. WTB provides services for the design of MBBR systems as below.
                                    </p>
                                    <p>
                                        BioChip carrier plays a very strong role in MBBR Technology particularly to provide higher removal rates and more reliable process stability. These two key points can be well achieved by our patented BioChip which is able to provide removal rates up to 10 times higher than any other conventional bio carriers by other competitive solutions.
                                    </p>
                                    <strong>Know-how and services:</strong>
                                    <ul>
                                        <li>MBBR plant design</li>
                                        <li>Design of process air supply</li>
                                        <li>Design of carrier retention screens</li>
                                        <li>Tank design</li>
                                        <li>Process engineering</li>
                                        <li>Commissioning</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row fifth' id='mbr'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <div className='row'>
                                {/* <div className='col-md-3'>
                                    <img src={airpic}></img>
                                </div> */}
                                <div className='col-md-12'>
                                    <h3>Membrane Bio-Reactor (MBR)</h3>
                                    <p>
                                        The term ‘membrane bioreactor’ (MBR) is generally used to define wastewater treatment processes where a perm-selective membrane eg; microfiltration or ultrafiltration is integrated with a biological process – a suspended growth bioreactor. Water Technology BD Ltd (WTB) is a renewed Company that working with Membrane Bio-Reactor (MBR).
                                    </p>
                                    <p>
                                        MBRs differ from ‘polishing’ processes where the membrane is employed as a discrete tertiary treatment step with no return of the active biomass to the biological process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row sixth' id='filter'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <div className='row'>
                                {/* <div className='col-md-3'>
                                    <img src={airpic}></img>
                                </div> */}
                                <div className='col-md-12'>
                                    <h3>Ultrafiltration</h3>
                                    <p>
                                        Water Technology BD Ltd (WTB) successfully working with Ultrafiltration (UF) that is a separation process using membranes with pore sizes in the range of 0.1 to 0.001 micron. Typically, UF membranes will remove high molecular-weight substances, colloidal materials, and organic and inorganic polymeric molecules. Low molecular-weight organics and ions such as sodium, calcium, magnesium chloride, and sulfate are not removed by UF Membranes. Because only high-molecular weight species are removed, the osmotic pressure differential across the UF Membrane surface is negligible. Low applied pressures are therefore sufficient to achieve high flux rates from an Ultrafiltration membrane. Flux of a membrane is defined as the amount of permeate produced per unit area of membrane surface per unit time. Generally flux is expressed as gallons per square foot per day (GFD) or as cubic meters per square meters per day.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row seven' id='belt'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <div className='row'>
                                {/* <div className='col-md-3'>
                                    <img src={airpic}></img>
                                </div> */}
                                <div className='col-md-12'>
                                    <h3>Belt Press</h3>
                                    <p>
                                        Belt Press is great for dewatering applications in wastewater treatment plants due to its compact design and reliable operating method with minimum operating complexity. Wastewater from the sludge tank is first treated with polymer to allow formation of stronger flocs and then fed into the belt press. The thickened sludge is conveyed along the belt and pressed between two belts to drain out water content from the sludge. The dry sludge is collected in a bin and the filtrated water is transferred back for re-treatment. Belt press offers much lower power consumption and is suitable to be used for start-up or upgrading plant.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row eight' id='screw'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <div className='row'>
                                {/* <div className='col-md-3'>
                                    <img src={airpic}></img>
                                </div> */}
                                <div className='col-md-12'>
                                    <h3>Screw Press</h3>
                                    <p>
                                        Screw Press can be applied in a wide variety of dewatering applications. The screw press consist of a cylindrical wedge wire basket which allows filtrate to be drained out and the solids in the basket are conveyed gently by the slow moving rotating screw upwards to the discharge end of the screw press. This dewatering press operates continuously and can achieve high efficient dewatering with low maintenance requirement. Screw press also has low footprint due to its compact design and flexible to work with in a wide range of industries.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tecnologies