import React from 'react'

function Feasibility() {
  return (
    <div className='feasibility'>
      <div className='banner'>
        <h3>
          Feasibility Study
        </h3>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='box'>
              <strong> Feasibility Study:</strong>

              <p>
                An analysis and evaluation of a proposed project to determine if it
                <ol>
                  <li>is technically feasible</li>
                  <li>feasible within the estimated cost</li>
                  <li>will be profitable. Feasibility studies are almost always conducted where large sums are at stake.</li>
                </ol>
                A feasibility study is used to determine the viability of an idea, such as ensuring a project is legally and technically feasible as well as economically justifiable. It tells us whether a project is worth the investment—in some cases, a project may not be doable. There can be many reasons for this, including requiring too many resources, which not only prevents those resources from performing other tasks but also may cost more than an organization would earn back by taking on a project that isn’t profitable.
              </p>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='box'>
              <strong> Areas of Project Feasibility</strong>
              <p>
                There are five types of feasibility study—separate areas that a feasibility study examines, described below.
              </p>

              <p>
                <strong>1. Technical Feasibility – </strong> this assessment focuses on the technical resources available to the organization. It helps organizations determine whether the technical resources meet capacity and whether the technical team is capable of converting the ideas into working systems
              </p>

              <p>
                <strong>2. Economic Feasibility – </strong> this assessment typically involves a cost/ benefits analysis of the project, helping organizations determine the viability, cost, and benefits associated with a project before financial resources are allocated.
              </p>

              <p>
                <strong>3. Legal Feasibility –  </strong> this assessment investigates whether any aspect of the proposed project conflicts with legal requirements like zoning laws, data protection acts, or social media laws. Let’s say an organization wants to construct a new office building in a specific location.
              </p>

              <p>
                <strong>4. Operational Feasibility – </strong> this assessment involves undertaking a study to analyze and determine whether—and how well—the organization’s needs can be met by completing the project.
              </p>
              <p>
                <strong>5. Scheduling Feasibility – </strong> this assessment is the most important for project success; after all, a project will fail if not completed on time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feasibility