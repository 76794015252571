import React from 'react'
import './DupontSeminar.css'
import seminar from '../../images/Dupont-Seminar/seminar.jpg'
import seminar2 from '../../images/Dupont-Seminar/seminar2.jpg'
import seminar3 from '../../images/Dupont-Seminar/seminar3.jpg'
import seminar4 from '../../images/Dupont-Seminar/seminar4.jpg'
import seminar5 from '../../images/Dupont-Seminar/seminar5.jpg'
import seminar6 from '../../images/Dupont-Seminar/seminar6.jpg'

function DupontSeminar() {
    return (
        <div className='Dupont-Seminar'>
            {/* <div className='banner'>
                <div className='opacity'></div>
                <h3>
                    Dupont Seminar
                </h3>
            </div> */}
            <div className='row'>
                <div className='col-md-12'>
                    <img src={seminar} alt="WTBL" />
                    <img src={seminar4} alt="WTBL" />
                    <img src={seminar5} alt="WTBL" />
                    <img src={seminar6} alt="WTBL" />
                    <img src={seminar2} alt="WTBL" />
                    <img src={seminar3} alt="WTBL" />
                </div>
            </div>

        </div>
    )
}

export default DupontSeminar