import React from 'react'
import contactImage from "../../images/contact-info.jpeg";

function Contact() {
    return (
        <div className='contact'>
            {/* <div className='banner'>
                <h3>
                    Contact Us
                </h3>
            </div> */}
            <section className='contact-image'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src={contactImage} alt="WTBL" />
                        <h3>
                            Contact Us
                        </h3>
                    </div>
                </div>
            </section>
            <section class="section contact-info">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6 col-mb-12 col-12">
                            <div class="contact-block mb-4 mb-lg-0">
                                <i class="fas fa-headset"></i>
                                <h5>Call Us</h5>
                                <p>
                                    <strong>Mobile :</strong>
                                    +88 01713565696,
                                    <br />
                                    +88 01713273156
                                </p>
                                <p>
                                    <strong>Phone :</strong>

                                    +88-02-58070365
                                </p>
                                <p>
                                    <strong>Fax :</strong>

                                    +88029111731
                                </p>
                                <p><strong>Sat – Thurs:</strong></p>
                                <p>09 AM-06 PM Dhaka Time</p>

                                <p><strong>Friday</strong></p>
                                <p>Closed</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-mb-12 col-12">
                            <div class="contact-block mb-4 mb-lg-0">
                                <i class="fas fa-envelope-open-text"></i>
                                <h5>Email Us</h5>
                                <p>hkfservice.inn@gmail.com</p>
                                <p>info@wtbl.com.bd</p>
                                <br></br>
                                <p><strong>Sat – Thurs:</strong></p>
                                <p>09 AM-06 PM Dhaka Time</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-mb-12 col-12">
                            <div class="contact-block ">
                                <i class="fas fa-map-marked"></i>
                                <h5>Location</h5>
                                <strong>Head Office</strong>
                                <p>House No: 1248,  Level: 4th,
                                    <br></br>
                                    Road No# 09, Mirpur DOHS,
                                    <br></br>
                                    Dhaka- 1216, Bangladesh.
                                </p>
                                <strong>Factory:</strong>

                                <p>329/330, DIT Industrial Area, Tongi, Gazipur.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-form-wrap section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title text-center">
                                <h5 className="mb-5">
                                    To Know Your Query, Please leave a meesage by using below form.
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <form id="contact-form" className="contact__form " noValidate>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="customerName">Name</label>
                                                    <input type="text" className="form-control" id="customerName" name="customerName" required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="emailAddress">Email</label>
                                                    <input type="text" className="form-control" id="emailAddress" name="emailAddress" required />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="mobileNo">Contact Number</label>
                                                    <input type="text" className="form-control" id="mobileNo" name="mobileNo" required />
                                                </div>
                                            </div>

                                            {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="mobileNo">Website</label>
                                                        <input type="text" className="form-control" id="mobileNo" name="mobileNo"  required />
                                                    </div>
                                                </div> */}

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="mobileNo">Subject</label>
                                                    <input type="text" className="form-control" id="mobileNo" name="mobileNo" required />
                                                </div>
                                            </div>

                                            <div className="form-group-2 mb-4">
                                                <label htmlFor="issueDetail">Message</label>
                                                <textarea type="text" className="form-control" rows={8} id="issueDetail" name="issueDetail" required />
                                            </div>
                                            <div className="text-left">
                                                <button className="btn btn-main mb-1" name="submit" type="submit"> Send Messege </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact