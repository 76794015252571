import React from 'react';
import "./Header.css";
import { useState } from 'react';
import { Link } from 'react-router-dom';
// import Logo from "./images/wtbl-logo.png";
import Logo from "../../images/wtbl-logo.png";

function Header() {
    const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

    return (
        <div>
            <div className="headerref aem-GridColumn aem-GridColumn--default--12">
                <div className={toggle ? 'component component-navigation open' : 'component component-navigation'}>
                    <div className="container">
                        <nav>
                            <div className="component-navigation--logo">
                                <div className={toggle ? "hamburger open" : "hamburger"} onClick={handleClick}>
                                    <input type="checkbox" /><span /><span /><span />
                                </div>
                                <Link className="navigation-logo" to="/"><img src={Logo} alt="WTBL" /></Link>
                                <div className="search-icon" id="userMobileIcon" data-icon-path="/content/dam/dupont/amer/us/en/corporate/general/icons/noun-user.svg" data-active-icon-path="/content/dam/dupont/amer/us/en/corporate/general/icons/noun-user-active2.svg">
                                </div>
                            </div>
                            <div className="component-navigation--items">
                                <ul className="component-navigation--items-top">
                                    <li>
                                        <div className="navigation-item-contain ">
                                            <Link to="/" className="navigation-item delay-1">Home</Link>
                                            <div className="hover" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="navigation-item-contain ">
                                            <Link className="navigation-item delay-2" to="/about">About Us</Link>
                                            <div className="hover" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="navigation-item-contain ">
                                            <Link className="navigation-item delay-3" to="#">Service</Link>
                                            <div className="hover" />
                                            <div className="navigation-sub">
                                                <div className=" nav-sub-items">
                                                    <ul>
                                                        <li className="crprate-hide-for-desk"> <Link className="navigation-item delay-1" to="#">Service</Link></li>
                                                        <li><Link className="navigation-item delay-1" to="#">Design, Fabrication & Maintenance</Link><i class='fa fa-angle-right'></i>
                                                            <div className="navigation-sub-sub">
                                                                <div className=" nav-sub-sub-items">
                                                                    <ul>
                                                                        <li className="crprate-hide-for-desk"> <Link className="navigation-item delay-1" to="#">Service</Link></li>
                                                                        <li><Link className="navigation-item delay-1" to="/effluent">Effluent Treatment Plant (ETP)</Link></li>
                                                                        <li><Link className="navigation-item delay-2" to="/discharge">Zero Discharge Plant</Link></li>
                                                                        <li><Link className="navigation-item delay-3" to="/tripler">3R Policy</Link></li>
                                                                        <li><Link className="navigation-item delay-3" to="/watertreatment">Drinking Water Treatment Plant</Link></li>
                                                                        <li><Link className="navigation-item delay-3" to="/sewagetreatment">Sewage Treatment Plant (STP)</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li><Link className="navigation-item delay-2" to="#">Environmental Assessment & Documentation</Link><i class='fa fa-angle-right'></i>
                                                            <div className="navigation-sub-sub">
                                                                <div className=" nav-sub-sub-items">
                                                                    <ul>
                                                                        <li className="crprate-hide-for-desk"> <Link className="navigation-item delay-1" to="#">Service</Link></li>
                                                                        <li><Link className="navigation-item delay-1" to="/eia">Environment Impact Assessment (EIA)</Link></li>
                                                                        <li><Link className="navigation-item delay-2" to="/emp">Environmental Management Plant (EMP)</Link></li>
                                                                        <li><Link className="navigation-item delay-3" to="/iee">Initial Environment Examination (IEE)</Link></li>
                                                                        <li><Link className="navigation-item delay-3" to="/environment">Environment and Social Assessment</Link></li>
                                                                        <li><Link className="navigation-item delay-3" to="/training">Environmental Training , Awareness & Education</Link></li>
                                                                        {/* <li><Link className="navigation-item delay-3" to="/economic">Master Plane of Economic Zone</Link></li> */}
                                                                        <li><Link className="navigation-item delay-3" to="/feasibility">Feasibility Study</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li><Link className="navigation-item delay-3" to="#">Chemicals & Machineries</Link><i class='fa fa-angle-right'></i>
                                                            <div className="navigation-sub-sub">
                                                                <div className=" nav-sub-sub-items">
                                                                    <ul>
                                                                        <li className="crprate-hide-for-desk"> <Link className="navigation-item delay-1" to="#">Service</Link></li>
                                                                        <li><Link className="navigation-item delay-1" to="/chemicals">Chemicals</Link></li>
                                                                        <li><Link className="navigation-item delay-1" to="/machineries">Machineries</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="navigation-item-contain ">
                                            <Link className="navigation-item delay-4" to="/clients">Client</Link>
                                            <div className="hover" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="navigation-item-contain ">
                                            <Link className="navigation-item delay-6" to="#">Technologies</Link>
                                            <div className="hover" />
                                            <div className="navigation-sub">
                                                <div className=" nav-sub-items">
                                                    <ul>
                                                        <li> <a className="navigation-item delay-1" href="/technologies#rotary">Rotary Drum Screen</a></li>
                                                        <li> <a className="navigation-item delay-1" href="/technologies#air">Dissolved Air Floatation (DAF)</a></li>
                                                        <li> <a className="navigation-item delay-1" href="/technologies#mbbr">Moving Bed Biofilm Reactor (MBBR)</a></li>
                                                        <li> <a className="navigation-item delay-1" href="/technologies#mbr">Membrane Bio-Reactor (MBR)</a></li>
                                                        <li> <a className="navigation-item delay-1" href="/technologies#filter">Ultrafiltration</a></li>
                                                        <li> <a className="navigation-item delay-1" href="/technologies#belt">Belt Press</a></li>
                                                        <li> <a className="navigation-item delay-1" href="/technologies#screw">Screw Press</a></li>
                                                        <li> <a className="navigation-item delay-1" href="/technologies#mutag">Mutag BioChip 30TM</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="navigation-item-contain ">
                                            <Link className="navigation-item delay-7" to="#">Products</Link>
                                            <div className="hover" />
                                            <div className="navigation-sub">
                                                <div className=" nav-sub-items">
                                                    <ul>
                                                        <li> <Link className="navigation-item delay-1" to="/chemicals">Chemicals</Link></li>
                                                        <li> <Link className="navigation-item delay-1" to="/machineries">Machineries</Link></li>
                                                        <li> <Link className="navigation-item delay-1" to="/biochip">Biochip 30TM</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <div className="navigation-item-contain ">
                                            <Link className="navigation-item delay-8" to="/gallery">Gallery</Link>
                                            <div className="hover" />
                                        </div>
                                    </li> */}
                                    <li>
                                        <div className="navigation-item-contain ">
                                            <Link className="navigation-item delay-9" to="/contact">Contact Us</Link>
                                            <div className="hover" />
                                        </div>
                                    </li>

                                    <li>
                                        <div className="navigation-item-contain ">
                                            <Link className="navigation-item delay-9" to="dupont">Dupont</Link>
                                            <div className="hover" />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="navigation-item-contain ">
                                            <Link className="navigation-item delay-9" to="dupontseminar">Dupont Seminar</Link>
                                            <div className="hover" />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Header