import React from 'react'

function Watersolution() {
    return (
        <div className='watersoluton-section'>
            <div className='container'>
                <h3>Water Technology BD Ltd & HKF are proud to provide its customers with a Total Water Treatment Solution</h3>
                <h4>Water Treatment Equipment, Chemicals, Machineries and Services developed over 10 years in the water business</h4>
                <div className='row'>
                    <div className='col-md-3'>
                        <div>
                            <h2>Design, Fabrication & Maintenance</h2>
                            <ul>
                                <li>Biological Treatment (MBBR,ASP)</li>
                                <li>Effluent Treatment plant (ETP)</li>
                                <li>Sewage treatment plant (STP)</li>
                                <li>Drinking Water Treatment Plant</li>
                                <li>3R Policy (Reduce, Reuse, Recycle )</li>
                                <li>Zero Discharge Plant</li>
                                <li>Water &amp; Heat Related Project</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div>
                            <h2>Environmental Assessment & Documentation</h2>
                            <ul>
                                <li>Environment Impact Assessment (EIA)</li>
                                <li>Environmental Management Plant (EMP)</li>
                                <li>Initial Environment Examination (IEE)</li>
                                <li>Environment and Social Assessment</li>
                                <li>Environmental Training , Awareness &amp; Education</li>
                                <li>Master Plane of Economic Zone</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div>
                            <h2>Chemicals & Biochips</h2>
                            <ul>
                                <li>Chemicals for ETP &amp; WTP</li>
                                <li>Chemicals for Boiler &amp; Cooling water Treatment program</li>
                                <li>HCL &amp; H2SO4</li>
                                <li>Mutag Biochips</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div>
                            <h2>Machineries</h2>
                            <ul>
                                <li>Belt Press filter</li>
                                <li>Screw Press</li>
                                <li>Blower</li>
                                <li>Dissolve Air Flotation (DAF)</li>
                                <li>Roots Blower Pump</li>
                                <li>Rotary Drum Screen</li>
                                <li>Scrapper Bridge</li>
                                <li>Sludge Feeding Pump</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='row'></div>
            </div>
        </div>
    )
}

export default Watersolution