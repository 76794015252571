import React from 'react'
import clients from '../../images/clients.jpg'

function Clients() {
    return (
        <div className='clients'>
            <div className='banner'>
                <h3>
                    Clients
                </h3>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='clients-logo'>
                            <h3>Our Partners Network</h3>

                            <img src={clients}/>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clients