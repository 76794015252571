import React from 'react'

function EMP() {
  return (
    <div className='emp'>
      <div className='banner'>
        <h3>
          Environmental Management Plant (EMP)
        </h3>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='box'>
              <strong> Environmental Management Plan (EMP):</strong>

              <p>Environmental Management Plan (EMP) is a project-specific action plan defining a comprehensive, formalized and coordinated framework for environmental management, which is appropriate to the environmental and institutional setting of the project. The EMP will set out, clearly and concisely:</p>

              <ul>
                <li>&nbsp;The objectives of environmental management together with the reasons for introducing an environmental management system.</li>
                <li>&nbsp;The impacts, which are to be controlled, and the control (mitigation) measures to be adopted.</li>
                <li>&nbsp;The responsibilities for implementation of the control measures and the organizational framework necessary for their implementation.</li>
                <li>&nbsp;The resources (including financial resources) necessary for effective management and monitoring, together with institutional strengthening and training requirements, which need to be implemented if effective environmental management is to be achieved.</li>
                <li>The overall program for establishment of effective environmental management for the project.</li>
              </ul>

              <p>
                Environmental monitoring is viewed as an essential tool to be used in environmental management, to assess the effectiveness of the benefit enhancement and mitigation measures, and to check that no unforeseen impacts are occurring. An environmental monitoring plan will therefore be developed, which is fully integrated with the management plan.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EMP