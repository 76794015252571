import React from 'react'

function Effluent() {
  return (
    <div className='effluent'>
      <div className='banner'>
        <h3>
          Effluent Treatment Plant (ETP)
        </h3>
      </div>
      <div className='container'>
        <div className='row first-row'>
          <div className='col-md-6'>
            <div className='box'>
              
              <p>
              <strong> Water Technology BD Ltd (WTB) & HKF Engineering & Trading </strong>
                are the nation’s leading wastewater treatment
                plant engineers with industrial wastewater treatment expertise successfully working with Effluent Treatment
                Plant or ETP is one type of waste water treatment method which is particularly designed to purify
                industrial waste water for its reuse and its aim is to release safe water to environment from the harmful
                effect caused by the effluent.
              </p>
              <p>
                Industrial effluents contain various materials, depending on the industry. Some effluents
                contain oils and grease, and some contain toxic materials (e.g., cyanide). Effluents from food
                and beverage factories contain degradable organic pollutants. Since industrial waste water contains
                a diversity of impurities and therefore specific treatment technology called ETP is required.
              </p>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='box'>
              <h3>Flow Chart of Effluent Treatment Plant:</h3>
              <ul>
                <li>Collection Tank</li>
                <li>Storage Tank</li>
                <li>Mixing &amp; Cooling</li>
                <li>Neutralization</li>
                <li>Chemical Coagulation</li>
                <li>Biological Oxidation Tank</li>
                <li>Sedimentation &amp; Separation of Sludge</li>
                <li>Sludge thickneer</li>
                <li>Filtration</li>
                <li>Discharge to Drain</li>
              </ul>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='box'>
              <h3>Benefits of ETP:</h3>
              <ul>
                <li>To clean industry effluent and recycle it for further use</li>
                <li>To reduce the usage of fresh water in industries</li>
                <li>To preserve natural environment against pollution</li>
                <li>To meet the standards for emission of pollutants set by the Government & avoid heavy penalty</li>
                <li>To reduce expenditure on water acquisition</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='row second-row'>
          <div className='col-md-6'>
            <div className='box'>
              <h3>Biological Treatment Plant</h3>
              <p>Water Technology BD Ltd (WTB) offers exclusively designed Biological treatment Plant that
                is an important and integral part of any wastewater treatment plant that treats wastewater
                from either municipality or industry having soluble organic impurities or a mix of the two
                types of wastewater sources. The obvious economic advantage, both in terms of capital investment
                and operating costs, of biological treatment over other treatment processes like chemical oxidation;
                thermal oxidation etc. has cemented its place in any integrated wastewater treatment plant.</p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='box'>
              <h3>Bio-Chemical Treatment Plant</h3>
              <p>
                Effluent is the stream of excess chemical liquor which is extracted from an industry after
                using in original operation. The excess dye liquor which is extracted from textile industry
                after dyeing from different processes are treated with various chemicals to remove or neutralize
                the toxic materials before discharging to environment (eg. Aground Water). Water Technology BD Ltd (WTB)
                provides Bio-chemical wastewater treatment processes that include chemical precipitation
                (coagulation, flocculation), ion exchange, neutralization and adsorption.
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='box'>
              <h3>Chemical Treatment Plant</h3>
              <p>Chemicals are used during wastewater treatment in an array of processes to expedite disinfection.
                These chemical processes, which induce chemical reactions, are called chemical unit processes,
                and are used alongside biological and physical cleaning chemical neutralization and stabilization,
                which can be applied to wastewater during cleaning. Water Technology BD Ltd (WTB) provides an excellent
                methodology for Chemical treatment Plant.</p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='box'>
              <h3>Effluent Treatment Plant (ETP) </h3>
              <p>
              Water Technology BD Ltd (WTB) provides water & wastewater treatment processes that include Biological
               Treatment Plant, Bio-Chemical Treatment Plant and Chemical Treatment Plant.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Effluent