import React from 'react'
import Herosection from '../HeroSection/Herosection';
import Information from '../Information/information';
import Workgallery from '../Workgallery/Workgallery';
import Homecontact from '../Homecontact/Homecontact';
import Ultrafilter from '../Ultrafilter/Ultrafilter';
import Watersolution from '../Watersolution/Watersolution';
import Newsfeed from '../Newsfeed/Newsfeed';

function Homesection() {
  return (
    <div>
        <Herosection />
      <Information />
      <Workgallery />
      <Ultrafilter />
      <Watersolution />
      <Homecontact />
      <Newsfeed />
    </div>
  )
}

export default Homesection