import React from 'react'

function WaterTreatment() {
    return (
        <div className='watertreatment'>
            <div className='banner'>
                <h3>
                    Drinking Water Treatment Plant
                </h3>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <p>
                                To produce mineral and domestic water at industrial level,<strong>Water Technology BD Ltd (WTB)</strong>  provide a range of mineral water drinking water treatment plants. These plants are one of our most popular utility plants that are known for its high efficiency, leak poof and less maintenance. We also provide installation& operation training along with onsite support for the plant to our clients.
                            </p>
                            <p>
                                The most common steps in water treatment used by Water Technology BD Ltd include:
                            </p>
                            <ul>
                                <li>Coagulation and Flocculation<br/>
                                    Coagulation and flocculation are often the first steps in water treatment. Chemicals with a positive charge are added to the water. The positive charge of these chemicals neutralizes the negative charge of dirt and other dissolved particles in the water. When this occurs, the particles bind with the chemicals and form larger particles, called floc.</li>
                                <li>Sedimentation<br/>
                                    During sedimentation, floc settles to the bottom of the water supply, due to its weight. This settling process is called sedimentation.</li>
                                <li>Filtration<br/>
                                    Once the floc has settled to the bottom of the water supply, the clear water on top will pass through filters of varying compositions (sand, gravel, and charcoal) and pore sizes, in order to remove dissolved particles, such as dust, parasites, bacteria, viruses, and chemicals.</li>
                                <li>Disinfection<br/>
                                    After the water has been filtered, a disinfectant (for example, chlorine, and chloramine) may be added in order to kill any remaining parasites, bacteria, and viruses, and to protect the water from germs when it is piped to homes and businesses</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WaterTreatment