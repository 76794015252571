import React from 'react'
import filter1 from "../../images/ultrafilter/filter1.jpg"
import filter2 from "../../images/ultrafilter/filter2.jpg"
import filter3 from "../../images/ultrafilter/filter3.jpg"
import filter4 from "../../images/ultrafilter/filter4.jpg"

function Ultrafilter() {
    return (
        <div className='filter-section container'>
            <h3>MEMCOR® CPII ULTRAFILTRATION SYSTEM</h3>
            <div className='row'>
                <div className='col-md-4'>
                    <div>
                        <p>FURTHERING SUBMERGED MEMBRANE SYSTEMS & LOWERING THE COST OF TREATED WATER</p>
                        <img src={filter1}></img>
                        <p>MEMCOR® CSII system takes the features of submerged membrane systems,
                            such as: high solids handling capability; low energy use; & low footprint and makes them better.</p>
                        <p>Building on over 30 years of experience in both supplying membranes and membrane systems,
                            the MEMCOR CSII system is the latest addition to the MEMCOR product family.</p>
                    </div>
                </div>
                <div className='col-md-8'>
                    <div className='row'>
                        <div className='col-md-5'>
                            <div>
                                <p>The system uses: the latest enhanced PVDF UF membrane & potting that is proven in the field;
                                    a proven backwash process; and enhanced the configuration to reduce both
                                    capital & operating costs.</p>
                                <p>
                                    The MEMCOR CSII system is ideal for new installations or for upgrades to existing Filter systems or Clarifiers.
                                    By using “double ended” filtration & a longer module energy usage is reduced; cutting membrane operating costs;
                                    decreasing the plant footprint while delivering exceptional value over the entire system life-cycle.
                                </p>
                                <h4>
                                    The MEMCOR CSII MemRACK™ assembly features
                                </h4>
                                <p>Proven L20N Modules: both high flux and high permeability;</p>
                                <p>Uses Nylon Filtrate & Air rack components from the Memcor CS MemRACK already proven in the field;</p>
                                <p>Flexible configuration to fit to tanks/cells of different sizes and depths;</p>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div>
                                <p>
                                    Uses the proven Memcor CS backwash process, while achieving lower air usage; and lower membrane operating costs
                                    Suited to “Open Rack” technology platforms
                                </p>
                                <img src={filter2}></img>
                                <img src={filter3}></img>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className='pipe-image'>
                                <img src={filter4}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ultrafilter