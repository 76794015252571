import React from 'react'

function EIA() {
    return (
        <div className='eia'>
            <div className='banner'>
                <h3>
                    Environment Impact Assessment (EIA)
                </h3>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <p>
                                <strong> Environmental Impact Assessment (EIA) </strong> is a comprehensive and detail examination of a proposed development initiative and the environment in which it is to be conducted and operated. EIA will be carried out in relation to legislative framework relevant to the environmental studies.</p>

                            <p>Baseline environmental conditions around the project site will be established during this stage of the study. The potential interaction between the project and its environmental and social setting will be established, involving identification, characterization and evaluation of all potential impacts.</p>

                            <p>A comprehensive checklist of all possible impacts associated with the pre-construction, construction and operational stages of the project will be prepared, and will form the basis for a simple matrix table showing for each impact:
                            </p>

                            <ul>
                                <li>The environmental components or values likely to be affected</li>
                                <li>Characterization in terms of major parameters (beneficial/adverse, direct/indirect, duration, area extent, effect on baseline conditions, etc</li>
                                <li>Evaluation of the significance of each impact (or issue), taking into account the principal characteristics</li>
                                <li>Brief comments as necessary to support assessment of significance</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='box'>
                            <p>
                                The key impacts identified during the scoping phase will then be investigated and described in detail.</p>

                            <p>A comparison of alternatives to the scope and form of the project, including the no-action case, will also be carried out, covering key impacts only. An environmental and social comparison of realignment alternatives will be prepared, so that these matters can be given due consideration in selection of the most appropriate. Uncertainties in impact assessment will be identified and evaluated in terms of their likely effect on the validity of conclusions regarding the overall environmental performance of the project.</p>

                            <p>Practical and cost-effective measures to enhance beneficial impacts and to avoid creation of adverse impacts or to minimize or offset them will be identified and recommendations made for their incorporation in designs, contract documentation etc. This aspect will cover all impacts, but will concentrate on key impacts, for which an assessment of residual impact will be made, assuming that all proposed enhancement or mitigation measures are fully adopted.</p>

                            <p>Wherever possible, mitigation will be based on the principle of planning and shaping the project in such a way as will avoid creation of adverse impacts. This is a significantly better and more cost-effective approach than allowing an impact to arise and then having to develop mitigation measures which may only be partially successful in minimizing problem.</p>

                            <p>The EIA will include an Environmental Management Plan (EMP). This will take the form of a project-specific action plan defining a comprehensive, formalized and coordinated framework for environmental management, which is appropriate to the environmental and institutional setting of the project.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EIA