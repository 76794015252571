import React from 'react'

function SewageTreatment() {
    return (
        <div className='sewagtreatment'>
            <div className='banner'>
                <h3>
                    Sewage Treatment Plant (STP)
                </h3>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='box'>
                            <p>
                                <strong>Water Technology BD Ltd (WTB) & HKF Engineering & Trading </strong>successfully working with Sewage treatment plant process that includes sewage treatment on primary, Secondary or biological and tertiary treatment process to improve quality of wastewater for recycle.
                            </p>
                            <p>
                                With increasing infrastructural development and water usage for various purposes such as domestic and commercial, there is generation of waste water.
                                Sewage treatment is the process of removing contaminants from wastewater, primarily from household sewage. It includes physical, chemical, and biological processes to remove these contaminants and produce environmentally safe treated wastewater (or treated effluent). A by-product of sewage treatment is usually a semi-solid waste or slurry, called sewage sludge that has to undergo further treatment before being suitable for disposal or land application.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row first-row'>
                    <div className='col-md-7'>
                        <div className='box'>
                            <p><strong>Tertiary treatment</strong></p>
                            <p>
                                The effluent then undergoes tertiary treatment/disinfection by incorporation of UV radiation or chlorination. Other methods such as sand filters and reverse osmosis may also be used instead for this stage of treatment, depending upon the nature of the sewage and the effluent from secondary treatment.
                            </p>
                            <p>
                                The term “sewage treatment plant” (or “sewage treatment works” in some countries) is nowadays often replaced with the term “wastewater treatment plant”.
                                Sewage can be treated close to where the sewage is created, which may be called a “decentralized” system or even an “on-site” system (in septic tanks, bio filters or aerobic treatment systems). Alternatively, sewage can be collected and transported by a network of pipes and pump stations to a municipal treatment plant. This is called a “centralized” system (see also sewerage and pipes and infrastructure).
                            </p>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='box'>
                            <strong>Secondary or biological treatment</strong>
                            <p>
                                This stage converts organic matter in sewage into stable forms through biological activities, resulting in secondary sedimentation. Common approaches are trickling filters and activated sludge method.
                            </p>
                            <p>
                                Trickling filters comprise an enclosed tank with a bed of bricks and a layer of microorganisms. The effluent enters the tank through an inlet and trickles over the bed layer by sprinklers. Microbial activities oxidize the organic matter in the effluent, resulting in the removal of fine solids, formation of sludge and an effluent with less organic solids.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='row second-row'>

                    <div className='col-md-4'>
                        <div className='box'>
                            <strong>Technologies Used</strong>
                            <p>ASP : Activated Sludge Process</p>
                            <p>MBBR : Moving Bed Bio reactor</p>
                            <p>SAFF : Submerged aerated Fixed Film</p>
                            <p>SBR : Sequential Bioreactor</p>
                            <p>MBR : Membrane Bio Reactor</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='box'>
                            <strong>Sewage Treatment Plant process</strong>
                            <p>Sewage can be treated by designing a sewage treatment plant (STP) which involves three stages: primary/preliminary, secondary and tertiary.</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='box'>
                            <strong>Primary treatment</strong>
                            <p>In a sewage treatment plant, sewage water is first allowed to pass through screens or grit chamber where large solids are removed. This step is followed by aeration/mixing in a tank and then primary sedimentation where suspended solids settle down. Primary treatment involves addition of a coagulant and aims at removing grits, coarse solids, oil and grease if any present.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SewageTreatment